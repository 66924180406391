import axios from "axios";
const url = "/api/v1/user";
class NotificationService{

  static getNotificationInfo(user,id)
  {
    let formData = {
      user:user,
      id:id
    }
    axios.defaults.headers.post["Content-Type"] = "application/json";

    return  axios.post(url+"/notif/clv", formData,
      { headers: { "Content-Type": "application/json" } }
    );

  }


    static setNotifications(id,debt,net,amnt) {
        let formData = {
            user:id,
            debt,
            net,
            amnt
          }
          axios.defaults.headers.post["Content-Type"] = "application/json";
      
          return  axios.post(url+"/notif", formData,
            { headers: { "Content-Type": "application/json" } }
          );
      }

    static getNotifications(id) {
        let formData = {
            user:id
           }
          axios.defaults.headers.put["Content-Type"] = "application/json";
      
          return  axios.put(url+"/notif", formData,
            { headers: { "Content-Type": "application/json" } }
          );
      }

      static setUserNotifications(id) {
        let formData = {
            user:id
           }
          axios.defaults.headers.post["Content-Type"] = "application/json";
      
          return  axios.post(url+"/nptpbr", formData,
            { headers: { "Content-Type": "application/json" } }
          );
      }

      static getUserNotifications(id) {
        let formData = {
            user:id
           }
          axios.defaults.headers.put["Content-Type"] = "application/json";
      
          return  axios.put(url+"/nptpbr", formData,
            { headers: { "Content-Type": "application/json" } }
          );
      }
      static setUpdateMessage(id) {
        let formData = {
          msgid:id
           }
          axios.defaults.headers.post["Content-Type"] = "application/json";
      
          return  axios.post(url+"/nuptpmbr", formData,
            { headers: { "Content-Type": "application/json" } }
          );
      }
}


export default NotificationService;