import Vue from 'vue'
import VueRouter from 'vue-router'
import { Role } from "./roles";
import AuthService from "@/services/AuthService"
Vue.use(VueRouter)
const user = JSON.parse(localStorage.getItem("auth"));
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () =>import('@/views/Home.vue'),
      meta: {
        //rol:user?user["rol"]:"null",
        authorize: [],
      
        pageTitle: "Home",
        breadcrumb: [
          {

            text: "Home",
            active: true,
          },
        ],
      },
    },
    {
      path: '/tokendetails',
      name: 'tokendetails',
      component: () =>import('@/views/tokendetails/TokenDetails.vue'),
      meta: {
        //rol:user?user["rol"]:"null",
        // authorize: [Role.Test],
        authorize: [],
        pageTitle: "Token Details",
        breadcrumb: [
          {

            text: "Token Details",
            active: true,
          },
        ],
      },
    },
    {
      path: '/account',
      name: 'account',
      component: () =>import('@/views/Account.vue'),
      meta: {
        //rol:user?user["rol"]:"null",
        // authorize: [Role.Test],
      
        pageTitle: "Account",
        breadcrumb: [
          {

            text: "Account",
            active: true,
          },
        ],
      },
    },
    {
      path: '/blocks',
      name: 'blocks',
      component: () =>import('@/views/Blocks.vue'),
      meta: {
         // authorize: [Role.Test],
        authorize: [],
        pageTitle: "Blocks",
        breadcrumb: [
          {

            text: "Blocks",
            active: true,
          },
        ],
      },
    },
    {
      path: '/blockdetails',
      name: 'blockdetails',
      component: () =>import('@/views/BlockDetails.vue'),
      meta: {
         // authorize: [Role.Test],
         authorize: [],
      
        pageTitle: "Block Details",
        breadcrumb: [
          {

            text: "Block Details",
            active: true,
          },
        ],
      },
    },
    {
      path: '/tokencomparison',
      name: 'tokencomparison',
      component: () =>import('@/views/TokenComparison.vue'),
      meta: {
        // authorize: [Role.Test],
        authorize: [],
      
        pageTitle: "Token Comparison",
        breadcrumb: [
          {

            text: "Token Comparison",
            active: true,
          },
        ],
      },
    },
    {
      path: '/collectionanalysis',
      name: 'collectionanalysis',
      component: () =>import('@/views/CollectionAnalysis.vue'),
      meta: {
        authorize: [],
      
        pageTitle: "Collection NFT",
        breadcrumb: [
          {

            text: "Collection NFT",
            active: true,
          },
        ],
      },
    },
    {
      path: '/collection',
      name: 'collection',
      component: () =>import('@/views/nftanalysis/CollectionNFT.vue'),
      meta: {
        authorize: [],
      
        pageTitle: "Collection NFT",
        breadcrumb: [
          {

            text: "Collection NFT",
            active: true,
          },
        ],
      },
    },
    
    {
      path: '/sqled',
      name: 'sqled',
      component: () =>import('@/views/SQLEditor.vue'),
      meta: {
        authorize: [Role.Test],
      
        pageTitle: "SQL Editor",
        breadcrumb: [
          {

            text: "SQL Editor",
            active: true,
          },
        ],
      },
    },
    {
      path: '/floorprice',
      name: 'floorprice',
      component: () =>import('@/views/FloorPrice.vue'),
      meta: {
        authorize: [Role.Test],
      
        pageTitle: "Floor Price",
        breadcrumb: [
          {

            text: "Floor Price",
            active: true,
          },
        ],
      },
    },
    {
      path: '/dashb',
      name: 'dashb',
      component: () =>import('@/views/DashBoardBuilder.vue'),
      meta: {
        authorize: [Role.Test],
      
        pageTitle: "Dashboard Builder",
        breadcrumb: [
          {

            text: "Dashboard Builder",
            active: true,
          },
        ],
      },
    },
    {
      path: '/dashlist',
      name: 'dashlist',
      component: () =>import('@/views/DashBoardList.vue'),
      meta: {
        authorize: [Role.Test],
      
        pageTitle: "My Dashboards",
        breadcrumb: [
          {

            text: "My Dashboard",
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      }},
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})
router.onError(error => {
  console.log(error.message)
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload()
  }
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

// router.beforeEach( async (to, from, next) => {
//   const { authorize } = to.meta;
//   const currentUser = user;


//   if (authorize) {
//     if (!currentUser) {
      
//       return next({ path: "/login"});
//     }

  
//     if (authorize.length && !authorize.includes(currentUser.rol)) {
//       console.log("Entramos")
//       return next({ path: "/" });
//     }
//   }
//   if(currentUser && to.name=="login")
//   {
//     return next({ path: "/" });
//   }
  

//   next();
  
 

  
  
 
// })
export default router
