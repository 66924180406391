import axios from "axios";
import { BehaviorSubject } from "rxjs";
const url = "/api/v1/auth";
const currentUser = new BehaviorSubject(
  JSON.parse(localStorage.getItem("auth"))
);
class AuthService {
  static currentUser() {
    return currentUser.value;
  }

  static getAuth(auth) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(url, {
          headers: {
            "Authorization": "Bearer "+ auth.token
          }
        });
      
        const data = res.data;
        resolve(data);
      } catch (e) {
        reject(e);
      }
    });
  }

  static insert(nusuario, nclave) {
    let formData = {
      nuser:nusuario,
      npass:nclave
    };
    axios.defaults.headers.post["Content-Type"] = "application/json";

    return  axios.post(url + "/save", formData,
    { headers: { "Content-Type": "application/json" }}
    );
  }
}

export default AuthService;